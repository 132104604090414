// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~swiper/swiper-bundle.css';
@import '~swiper/swiper.min.css';
@import '~@ionic/angular/css/ionic-swiper.css';
@import '~currency-flags/dist/currency-flags.min.css';

a {
  cursor: pointer;
}

@font-face {
  font-family: 'Myriad Pro Regular';
  src: url('./assets/fonts/myriad-pro-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro Light';
  src: url('./assets/fonts/myriad-pro-light.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Light';
  src: url('./assets/fonts/Lato-Lig.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Regular';
  src: url('./assets/fonts/Lato-Reg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Chevin Medium';
  src: url('./assets/fonts/chevin-medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Chevin Thin';
  src: url('./assets/fonts/chevin-thin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SansSerif Medium';
  src: url('./assets/fonts/SEBSansSerif-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SansSerif Regular';
  src: url('./assets/fonts/SEBSansSerif-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arial';
  src: url('./assets/fonts/Arial.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arial Bold';
  src: url('./assets/fonts/Arial.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Sparkasse Rg';
  src: url('./assets/fonts/Sparkasse_Rg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sparkasse Bd';
  src: url('./assets/fonts/Sparkasse_Bd.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Sparkasse BdIt';
  src: url('./assets/fonts/Sparkasse_BdIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Sparkasse It';
  src: url('./assets/fonts/Sparkasse_It.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

//toast element styling
.ios ion-toast {
  margin-top: -45px;
}

ion-toast.error-toast {
  --background: var(--error);
  --color: var(--menu-text);
  --button-color: var(--menu-text);
  font: 14px var(--font-regular);
}

ion-toast.proceed-toast {
  --background: var(--highlight);
  --color: var(--menu-text);
  --button-color: var(--menu-text);
  font: 14px var(--font-regular);
}

ion-toast.success-toast {
  --background: var(--success);
  --color: var(--menu-text);
  font: 14px var(--font-regular);
}

ion-toast.warn-toast {
  --background: var(--warn);
  --color: var(--menu-text);
  font: 14px var(--font-regular);
}

ion-toast.error-toast-pin-code {
  --background: var(--error);
  --color: var(--color-text-header);
  font: 14px var(--font-regular);
  text-align: center;
  border-radius: 5px;
}

ion-toast.warning-toast-maintenance-window {
  --background: var(--warn);
  --color: var(--base);
  --button-color: var(--base);
  font: 15px var(--font-regular);
  text-align: left;
  border-radius: 5px;

  .toast-content {
    padding-left: 10px;
  }
}

//action sheet element styling
ion-action-sheet.information {
  --button-color: var(--base);

  button {
    contain: content !important;
    height: auto !important;
  }

  button.action-sheet-button {
    background: var(--background-primary);
  }

  button.icon {
    pointer-events: none;

    ion-icon {
      font-size: 36px !important;
    }
  }

  button.text {
    line-height: 1.2em;
    font-size: 18px;
    pointer-events: none;
  }
}

ion-action-sheet.alert {
  --button-color: var(--base);

  button {
    contain: content !important;
    height: auto !important;
  }

  button.action-sheet-button {
    background: var(---background-primary);
  }

  button.icon {
    pointer-events: none;

    ion-icon {
      font-size: 46px !important;
    }
  }

  button.text {
    line-height: 1.2em;
    font-size: 18px;
    pointer-events: none;
  }

  button.alert-button {
    --button-color: var(--error);
    font-size: 19px;
    font-weight: 800;
  }

  button.enable-button {
    --button-color: var(--btn-main);
    font-size: 19px;
    font-weight: 800;
  }

  button.cancel-button {
    --button-color: var(--btn-main);
    font-size: 19px;
    font-weight: 800;
  }
}

//Angular Material elements styling
.mat-mdc-card {
  padding: 5px !important;
}

.mat-icon {
  width: 30px !important;
}

.mat-mdc-progress-bar {
  height: 8px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-mdc-icon-button {
  margin-right: 5px !important;
  padding: 0 !important;
}

.mat-mdc-card-content, .mat-mdc-card-subtitle {
  font-size: 12px;
}

.mat-calendar-body-selected {
  background-color: var(--highlight) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-infix {
  border-top: 0;
}

//toggle element style
.toggle-size {
  width: 72px;
  height: 35px;
  display: inline-block;
}

.toggle-ios.toggle-checked .toggle-icon {
  background-color: var(--menu-bg);
}

.toggle-md.toggle-checked .toggle-icon {
  background-color: var(--menu-bg);
}

ion-toggle {
  --track-background-checked: var(--menu-bg);
  --handle-background-checked: var(--background-primary);
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  -webkit-padding-end: 0;
  padding-inline-end: 0;
  zoom: 0.8;
}

//checkbox element styling
ion-checkbox {
  --background-checked: var(--btn-main);
  --border-color: var(--btn-main);
  --border-color-checked: var(--btn-main);
}

//buttons
.main-btn {
  width: 100%;
  height: 45px;
  --background: var(--btn-main);
  --background-activated: var(--main);
  color: var(--menu-text);
  font: bold 18px var(--font-regular);
  --border-radius: var(--button-border-radius);
  line-height: 40px;
  white-space: nowrap;
}

.main-btn-outline {
  width: 100%;
  height: 45px;
  --background: var(--background-primary);
  --background-activated: var(--background-primary);
  color: var(--btn-main);
  font: bold 18px var(--font-regular);
  border: 2px solid var(--btn-main);
  --border-radius: var(--button-border-radius);
  border-radius: var(--button-border-radius);
  line-height: 40px;
  white-space: nowrap;
}

.secondary-btn {
  color: var(--main);
  background: var(--background-primary);
  font: bold 18px var(--font-regular);
  border: 1px solid var(--delimiter);
  border-radius: var(--instrument-button-border-radius);
  width: 100%;
  height: 35px;
  margin: 0;
  padding: 0;
  z-index: 1;
  position: relative;
}

.inactive-secondary-btn {
  color: var(--base);
  background: var(--background-secondary);
  font: 18px var(--font-regular);
  z-index: 0;
}

.main-input {
  background: var(--background-secondary);
  padding-left: 7px;
  border-radius: 10px;
  border-width: 0;
  width: 100%;
  height: 40px;
}

//delimiters
.bottom-delimiter {
  border-bottom: 1px solid var(--delimiter);
}

.top-delimiter {
  border-top: 1px solid var(--delimiter);
}

.selector-page-title-container {
  --min-height: 60px;
}

//ion item delimiter styling - used for lists alignments
.et-ion-item {
  padding-right: 15px;
  --border-color: var(--delimiter);
}

//action card styling - used as selection pages entry point
.action-card {
  --background: var(--background-primary);
  border-radius: var(--button-border-radius);
  border: solid 1px var(--delimiter);
  position: relative;
  width: 65px;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;

  ion-grid {
    height: 30px;
    padding: 0;
  }

  ion-row, ion-col {
    height: 30px;
  }

  .col-align {
    padding: 0;
    text-align: center;
  }

  .disabled {
    --background: var(--background-secondary);
  }

  ion-icon {
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .icon-padding {
    float: right;
    right: 3px;
  }
}

.card-medium-format {
  width: 95px;
  padding-left: 3px;
}

.card-wide-format {
  width: 120px;
}

.action-card-text {
  color: var(--main);
  font: bold 16px var(--font-regular);
  height: 30px;
  display: inline-flex;
  align-items: center;
}

.card-wide-format-text {
  font: bold 14px var(--font-regular);
}

@media (max-width: 321px) {
  .action-card-text {
    font: bold 14px var(--font-regular);
  }

  .card-wide-format {
    width: 105px;
  }

  .card-wide-format-text {
    font: bold 12px var(--font-regular);
  }
}

//background highlight
.background-highlight {
  background-color: var(--background-secondary);
}

//market rate container styling
.market-rate-container {
  margin: 10px;
  border: 1px solid var(--delimiter);
  border-radius: 10px;
}

.market-rate-container-delimiter {
  width: 100%;
  border-bottom: 1px solid var(--delimiter);
  background-color: var(--background-secondary);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.market-rate-container-padding {
  margin: 10px;
}

@media (min-width: 321px) {
  .market-rate-container-padding {
    margin: 10px 20px 10px 20px;
  }
}

//input delimiter styling
.input-delimiter {
  border-bottom: 1px solid var(--delimiter);
  border-radius: var(--input-border-radius);
}

.ion-valid.sc-ion-input-ios-h, .ion-valid.sc-ion-textarea-ios-h, 
.ion-valid.sc-ion-input-md-h, .ion-valid.sc-ion-textarea-md-h {
  --highlight-color: var(--highlight);
}


ion-select {
  --highlight-color-valid: none;
}

.custom-input {
  ion-input {
    --padding-top: 6px;
    --padding-bottom: 10px;
    --padding-start: 0;
    width: 90%;
    font: 14px var(--font-regular);
    color: var(--base);
    margin-top: 15px;
  }

  ion-col {
    padding-top: 2px;
    padding-bottom: 0;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

mat-calendar {
  .mat-mdc-icon-button {
    color: var(--highlight);
  }

  .mat-calendar-table-header {
    color: var(--highlight);
  }
}

.et-alert {
  font: var(--font-regular);

  .alert-title {
    color: var(--highlight);
  }
}

@media(max-height: 667px) {
  mat-calendar {
    .mat-calendar-controls {
      margin-top: 0;
    }

    .mat-calendar-header {
      padding-top: 0;
    }
  }
}

.no-padding-start {
  padding-left: 0;
}

.no-padding-end {
  padding-right: 0;
}

.no-horizontal-padding {
  padding-right: 0;
  padding-left: 0;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.glossary {
  color: var(--highlight);
}

.vertical-align {
  margin-top: auto;
  margin-bottom: auto;
}

.swiper-pagination {
  bottom: 0 !important;
}

.selector {
  border: 1px solid black;
  border-radius: 5px;
  font: 16px var(--font-regular);
  padding-left: 15px;
  text-align: center;
}

ion-spinner {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100px;
  --color: var(--highlight);
  transform: scale(2);
}

.support-hours-alert {
  @media (min-height: 667px) {
    --width: 90%;
    --height: 50%;
  }

  @media (min-height: 740px) {
    --width: 95%;
    --height: 45%;
  }

  @media (min-height: 844px) {
    --width: 85%;
    --height: 40%;
  }

  --width: 95%;
  --height: 50%;
}

.email-consent-popup {
  --width: 90%;
  --height: auto;
}

.flag-border {
  border: 1px solid var(--delimiter);
  border-radius: 2px;
}

app-bubble-chart {
  svg path.highcharts-color-0 {
    fill: var(--chart-bubble-positive);
  }

  svg path.highcharts-color-1 {
    fill: var(--chart-bubble-negative);
  }

  svg path.highcharts-point-select {
    stroke-width: 0;
  }

  .currency-selected {
    svg path.highcharts-point:not(.highcharts-point-select) {
      fill: var(--disabled);
    }
  }

  .first-tooltip-row {
    color: var(--ion-color-primary);
    font-size: 14px;
  }

  .second-tooltip-row {
    color: var(--base-light);
    margin-top: 5px;
    font-size: 10px;
  }

  svg g text {
    font-weight: bold;
  }

  app-position-overview-details-modal {
    svg * .trading-currency-buy rect.highcharts-point {
      fill: var(--chart-trading-currency-buy);
    }

    svg * .trading-currency-sell rect.highcharts-point {
      fill: var(--chart-trading-currency-sell);
    }

    svg * .contra-currency-buy rect.highcharts-point {
      fill: var(--chart-contra-currency-buy);
    }

    svg * .contra-currency-sell rect.highcharts-point {
      fill: var(--chart-contra-currency-sell);
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.product-badge {
  background-color: var(--icon-primary);
  border-radius: 3px;
  color: white;
  padding: 2px;
  font-size: 12px;
  font-weight: bold;
  width: fit-content;
  max-width: 100%;
  float: right;
}

.text-pop-up{
  font-size: 12px;
}

ion-app {
  margin-top: env(safe-area-inset-top);
}

.status-bar-visible ion-header {
  margin-top: env(safe-area-inset-top);
}